import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Icon",
  code: "Icon"
};
export const _frontmatter = {
  "menuLabel": "Icon",
  "sortOrder": 9.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Icon`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-icon--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Icon } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`icon`}</inlineCode>{` component needs a `}<inlineCode parentName="p">{`name`}</inlineCode>{` prop that resolves the icon. The full list of icons can be viewed on our `}<a parentName="p" {...{
        "href": "/foundations/iconography"
      }}>{`iconography library`}</a>{`.`}</p>
    <p>{`Some name resolution details:`}</p>
    <ul>
      <li parentName="ul">{`For flags, you can pass in the country name, and it should resolve the flag. (e.g. America)`}</li>
      <li parentName="ul">{`If the icon name begins with `}<inlineCode parentName="li">{`icon_`}</inlineCode>{` you can omit `}<inlineCode parentName="li">{`icon_`}</inlineCode>{` if you'd like.  (e.g. `}<inlineCode parentName="li">{`icon_ui_star`}</inlineCode>{` can be `}<inlineCode parentName="li">{`ui_star`}</inlineCode>{`)`}</li>
    </ul>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The Icon component provides a visually distinguishable display for icons, utilizing appropriate color contrast and size to ensure icons are easily noticeable. It also includes alternative text or aria-labels for screen readers to announce the purpose or meaning of each icon.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
   The Icon component itself is not directly operable, but it supports integration with interactive elements such as Icon Buttons that users can interact with. Users can activate the associated functionality or action by interacting with the corresponding Icon Button.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Icon component is clear through appropriate labeling or tooltips associated with each icon. Users can easily understand the functionality and meaning conveyed by the displayed icons. The "name" prop allows users to specify the desired icon, and the component offers a comprehensive list of icons in the iconography library for reference.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Icon component is designed to be compatible with assistive technologies, ensuring that screen readers can properly identify the icons and their associated functionality. It also adheres to the provided name resolution details, allowing users to access icons by passing appropriate values to the "name" prop. The component functions consistently across different browsers and platforms.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      